export const { gettext, pgettext, ngettext } = window

/**
 * Executes a callback function once the DOM is fully loaded or immediately if the DOM is already ready.
 * This ensures that the callback function is executed at the right time in the document's loading process.
 * @param {Function} fn - The callback function to execute when the document is ready.
 */
export function onReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

/**
 * Returns the first element within the document (or specified base element) that matches the specified CSS selector.
 * It simplifies the process of selecting a single DOM element.
 * @param {string} selector - The CSS selector to match against elements.
 * @param {Element|Document} [base=document] - The base element or document to query from. Defaults to the global document.
 * @returns {Element|null} The first matching Element or null if no matches are found.
 */
export function qs(selector, base = document) {
  return base.querySelector(selector)
}

/**
 * Returns all elements within the document (or specified base element) that match the specified CSS selector.
 * It simplifies the process of selecting multiple DOM elements based on a CSS selector.
 * @param {string} selector - The CSS selector to match against elements.
 * @param {Element|Document} [base=document] - The base element or document to query from. Defaults to the global document.
 * @returns {NodeList} A NodeList of all matching Elements, which could be empty if no matches are found.
 */
export function qsa(selector, base = document) {
  return base.querySelectorAll(selector)
}
