import Splide from "@splidejs/splide"
import { qsa } from "./utils"

export const initTestimonialSlider = () => {
  const sliders = qsa("[data-testimonial-slider]")

  sliders.forEach((slider) => {
    const splide = new Splide(slider, {
      type: "loop",
      pagination: false,
      width: "100%",
      snap: true,
      autoplay: true,
      // updateOnMove:true,
    })

    splide.mount()
  })
}
