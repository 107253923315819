import { render } from "preact"
import { useEffect, useState } from "preact/hooks"
import { refreshCaptchaToken } from "./captcha"
import { gettext, qs } from "./utils"

export const initCommentForm = () => {
  const commentForm = qs("form#comment-form")

  if (commentForm) {
    commentForm.onsubmit = (e) => {
      e.preventDefault()

      refreshCaptchaToken(commentForm)
        .then(() => {
          const formData = new FormData(commentForm)
          return fetch(commentForm.action, {
            method: "POST",
            credentials: "same-origin",
            headers: {
              "X-CSRFToken": document.cookie.match(/csrftoken=(\w+?)\b/)[1],
            },
            body: formData,
          })
        })
        .then((response) => {
          if (response.status === 201) {
            return response.json()
          }
          commentForm.classList.add("form--error")
          commentForm.reset()
        })
        .then((json) => {
          if (json) {
            //auto populate success message with json fields where available
            const successNode = qs(".form__success-text")
            for (const field in json) {
              successNode.innerHTML = successNode.innerHTML.replace(
                `{${field}}`,
                json[field],
              )
            }
            commentForm.classList.add("form--success")
            commentForm.classList.remove("form--error")
            commentForm.reset()
          }
        })
        .catch((error) => {
          console.error(error)
          commentForm.classList.add("form--error")
        })
    }
  }
}

const formatText = (text) => {
  return text.replace(/(\r\n|\n|\r)/gm, "<br/>")
}

const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}

export const initComments = () => {
  const wrapper = qs("#comments")
  if (wrapper) {
    const status = wrapper.getAttribute("data-comments-status")
    render(<CommentsComponent status={status} />, wrapper)
  }
}

const CommentsComponent = ({ status }) => {
  const [comments, setComments] = useState(null)
  // const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const resetData = () => {
    setLoading(true)
    // setCount(0)
    setComments(null)
  }

  useEffect(() => {
    fetch("./comments/", {
      method: "GET",
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
      })
      .then((json) => {
        // setCount(json.count)
        if (json.comments.length) {
          setComments(json.comments)
        }

        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        resetData()
      })

    return () => {
      resetData()
    }
  }, [])

  return loading ? (
    <span class="comments__loading-message">
      <p>{gettext("Lade Kommentare...")}</p>
    </span>
  ) : comments?.length ? (
    <>
      {/* <span class="comments__count-message">{count}</span> */}
      <div class="comments__list">
        {comments.map((comment) => (
          <article key={comment.created_at} class="comment-item">
            <div class="comment-item__meta">
              <strong>{comment.author_name}</strong>,{" "}
              <time datetime={comment.created_at}>
                {new Date(Date.parse(comment.created_at)).toLocaleString(
                  "de-CH",
                  dateOptions,
                )}
              </time>
            </div>
            <div class="comment-item__text">
              <p
                dangerouslySetInnerHTML={{
                  __html: formatText(comment.text),
                }}
              />
            </div>
          </article>
        ))}
      </div>
    </>
  ) : status !== "inactive" ? (
    <span class="comments__empty-message">
      <p>{gettext("Noch keine Kommentare vorhanden")}</p>
    </span>
  ) : null
}
