/* global grecaptcha */
export const refreshCaptchaToken = (form) => {
  return new Promise((resolve, reject) => {
    const input = form.querySelector("input.g-recaptcha")
    const captchaSiteKey = input.dataset.sitekey

    return grecaptcha.execute(captchaSiteKey).then(
      (token) => {
        input.value = token
        resolve(form)
      },
      (v) => reject(v),
    )
  })
}

export const initRecaptchaAutorefresh = () => {
  const forms = document.querySelectorAll(
    "form:not([data-disable-recaptcha-token-autoreload])",
  )

  forms.forEach((form) => {
    form.addEventListener("submit", (e) => {
      const inputExists = form.querySelector("input.g-recaptcha")
      if (inputExists) {
        e.preventDefault()
        refreshCaptchaToken(form).then((form) => form.submit())
      }
    })
  })
}
