import { render } from "preact"
import { useEffect, useState } from "preact/hooks"
import { qsa } from "./utils"

const TEMPLATE =
  "https://www.googleapis.com/customsearch/v1?key=KEY&cx=CX&q=QUERY&start=STARTINDEX&callback=CALLBACK"
const NO_QUERY = 1
const DO_SEARCH = 2
const SEARCHING = 3
const SUCCESS = 4

export function renderSearch(el) {
  render(<Search cseCx={el.dataset.cseCx} cseKey={el.dataset.cseKey} />, el)
}

const loadResults = ({ cseCx, cseKey, query, startIndex }) => {
  window.history.replaceState({}, "", `?q=${query}`)

  const script = document.createElement("script")
  script.setAttribute("data-cse", "true")
  script.setAttribute(
    "src",
    TEMPLATE.replace(/KEY/, cseKey)
      .replace(/CX/, cseCx)
      .replace(/QUERY/, encodeURIComponent(query))
      .replace(/CALLBACK/, "cseHandler")
      .replace(/STARTINDEX/, startIndex),
  )
  qsa("script[data-cse]").forEach((el) => el.parentNode.removeChild(el))
  document.body.appendChild(script)
}

const initialQuery = (() => {
  const url = new URL(document.location)
  return url.searchParams.get("q") || ""
})()

const Search = ({ cseCx, cseKey }) => {
  const [query, setQuery] = useState(initialQuery)
  const [startIndex, setStartIndex] = useState(1)
  const [resultItems, setResultItems] = useState([])
  const [response, setResponse] = useState(null)
  const [currentState, setCurrentState] = useState(
    initialQuery ? DO_SEARCH : NO_QUERY,
  )

  useEffect(() => {
    window.cseHandler = (data) => {
      setCurrentState(SUCCESS)
      setResponse(data)
      if (data.items) {
        setResultItems([...resultItems, ...data.items])
      }
    }
    return () => {
      window.cseHandler = null
    }
  })

  useEffect(() => {
    // Actually load those results
    if (currentState === DO_SEARCH) {
      setCurrentState(SEARCHING)
      loadResults({ cseCx, cseKey, query, startIndex })
    }
  }, [currentState, startIndex, cseCx, cseKey])

  return (
    <>
      <form
        class="form form--standard"
        method="get"
        onSubmit={(e) => {
          e.preventDefault()
          setCurrentState(DO_SEARCH)
          setStartIndex(1)
          setResultItems([])
        }}
      >
        <div class="form__item form__item--textinput">
          <label htmlFor="id_q" className="no-bottom-margin">
            Suchbegriff eingeben *
          </label>
          <input
            className="search-field"
            type="text"
            name="q"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            id="id_q"
            autofocus
          />
        </div>

        <button className="button" type="submit">
          Suche
        </button>
      </form>
      {query ? (
        <p class="search-results-meta">
          Ergebnisse für <span className="highlighted">{query}</span>:
        </p>
      ) : null}
      {currentState === SUCCESS || resultItems.length ? (
        <ResultList
          resultItems={resultItems}
          hasNextPage={!!response.queries?.nextPage}
          onNextPage={() => {
            setCurrentState(DO_SEARCH)
            setStartIndex(response.queries.nextPage[0].startIndex)
          }}
        />
      ) : null}
      {currentState === SEARCHING ? (
        <div className="search-results flow">
          <h2>Suche läuft...</h2>
        </div>
      ) : null}
    </>
  )
}

const ResultList = ({ resultItems, hasNextPage, onNextPage }) => {
  if (resultItems.length) {
    return (
      <div className="search-results flow">
        {resultItems.map((item, index) => (
          <ResultItem key={index} {...item} />
        ))}

        {hasNextPage ? (
          <button
            type="button"
            className="button float-right"
            onClick={onNextPage}
          >
            Mehr Ergebnisse laden
          </button>
        ) : null}
      </div>
    )
  }
  return (
    <div className="search-results flow">
      <h2>Keine Ergebnisse gefunden</h2>
    </div>
  )
}

const ResultItem = (props) => {
  return (
    <a href={props.link} className="search-result">
      <h4
        className="search-result__title"
        dangerouslySetInnerHTML={{ __html: props.htmlTitle }}
      />
      <p className="search-result__content">{props.snippet}</p>
      <small style="text-decoration:underline">{props.link}</small>
    </a>
  )
}
