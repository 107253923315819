function throttle(fn, wait) {
  let time = Date.now()
  return () => {
    if (time + wait - Date.now() < 0) {
      fn()
      time = Date.now()
    }
  }
}

class SectionScroll {
  constructor(nextButton, prevButton, dataAnchor) {
    this.nextButton = nextButton
    this.prevButton = prevButton
    this.dataAnchor = dataAnchor

    this.prev = []
    this.next = []

    if (window.scrollY === 0) {
      this.dataAnchor[0].classList.add("active")
    }

    this.addEventListeners()
    this.setPosition()
  }

  setPosition = () => {
    this.navArray = [...this.dataAnchor]
    const activeElement = document.querySelector(".anchor.active")
    const activePosition = this.navArray.indexOf(activeElement)

    const findPosition = {
      "+": (activePosition, value) => activePosition + value,
      "-": (activePosition, value) => activePosition - value,
    }

    const usePlus = true
    this.prev = findPosition[usePlus ? "-" : "+"](activePosition, 1)
    this.next = findPosition[usePlus ? "+" : "-"](activePosition, 1)

    if (activePosition === this.navArray.length - 1) {
      this.next = this.navArray.length - 1
    }
    if (activePosition === 0) {
      this.prev = 0
    }

    this.prevButton.classList.toggle("disabled", activePosition === 0)
    this.nextButton.classList.toggle(
      "disabled",
      activePosition === this.navArray.length - 1,
    )

    if (this.navArray[this.prev]) {
      const prevPosition = this.navArray[this.prev].getAttribute("href")
      this.prevButton.setAttribute("href", prevPosition)
    }

    const nextPosition = this.navArray[this.next].getAttribute("href")
    this.nextButton.setAttribute("href", nextPosition)
  }

  setActive = () => {
    for (const el of this.dataAnchor) {
      el.parentElement.classList.toggle(
        "active",
        el.classList.contains("active"),
      )
    }
  }

  addEventListeners = () => {
    document.addEventListener("DOMContentLoaded", this.setActive)
    document.addEventListener("DOMContentLoaded", this.setPosition)
    window.addEventListener("scroll", throttle(this.setActive, 25))
    window.addEventListener("scroll", throttle(this.setPosition, 25))
  }
}

export const initSectionScroll = () => {
  // if (document.querySelector("body.layout--signup")) {
  //   let scrolledThreshold =
  //     document.querySelector("body.layout--signup header.header").offsetHeight -
  //     25

  //   function _throttle(func, delay) {
  //     let lastCall = 0
  //     return function (...args) {
  //       const now = new Date().getTime()
  //       if (now - lastCall < delay) {
  //         return
  //       }
  //       lastCall = now
  //       return func(...args)
  //     }
  //   }

  //   window.addEventListener(
  //     "scroll",
  //     _throttle(() => {
  //       if (window.pageYOffset > scrolledThreshold) {
  //         document.body.classList.add("scrolled")
  //       } else {
  //         document.body.classList.remove("scrolled")
  //       }
  //     }, 100),
  //   )
  // }
  const nextButton = document.querySelector(".section-button--next")
  const prevButton = document.querySelector(".section-button--back")
  const dataAnchor = document.querySelectorAll("[data-anchor]")

  if (dataAnchor.length && nextButton && prevButton) {
    new SectionScroll(nextButton, prevButton, dataAnchor)
  }
}
