class ToggleElements {
  constructor({
    singleOpen = true,
    checkInitialHash = true,
    updateHash = false,
  } = {}) {
    document.body.addEventListener("click", this.handleElementClick.bind(this))

    this.singleOpen = singleOpen
    if (!singleOpen && updateHash) {
      console.warn("singleOpen must be true to use updateHash")
    }
    this.updateHash = singleOpen && updateHash

    window.addEventListener("resize", this.handleResize.bind(this))
    window.addEventListener("load", this.handleLoad.bind(this))

    if (checkInitialHash && window.location.hash) {
      const slug = window.location.hash.substring(1)
      const faq = document.querySelector(`[data-hash="${slug}"]`)

      if (faq && !this.isOpen(faq)) {
        this.toggleElement(faq)
      }
    }
  }

  // fix sizes and positions on resize
  handleResize() {
    const elements = document.querySelectorAll(
      "[data-toggle-callback-on-resize]",
    )
    for (const el of elements) {
      const callbackScope =
        el.closest(el.getAttribute("data-toggle-callback-scope")) ||
        document.querySelector(el.getAttribute("data-toggle-callback-scope"))
      this[el.getAttribute("data-toggle-callback")].call(
        callbackScope,
        el.getAttribute("data-toggle-class") || "toggled",
      )
    }
  }

  // update height e.g. after images are done loading
  handleLoad() {
    const elements = document.querySelectorAll("[data-toggle-callback-on-load]")
    for (const el of elements) {
      const callbackScope =
        el.closest(el.getAttribute("data-toggle-callback-scope")) ||
        document.querySelector(el.getAttribute("data-toggle-callback-scope"))
      this[el.getAttribute("data-toggle-callback")].call(
        callbackScope,
        el.getAttribute("data-toggle-class") || "toggled",
      )
    }
  }

  handleElementClick(e) {
    if (e.target.matches("[data-toggle-trigger], [data-toggle-trigger] *")) {
      e.preventDefault()
      const target = e.target.closest("[data-toggle-element]")

      if (target) {
        if (this.singleOpen) {
          const openOtherElements = document.querySelectorAll(
            `[data-toggle-element].${
              target.getAttribute("data-toggle-class") || "toggled"
            }`,
          )
          for (const el of openOtherElements) {
            if (el !== target.closest("[data-toggle-element]")) {
              this.toggleElement(el)
            }
          }
        }

        this.toggleElement(target)

        if (this.updateHash) {
          if (this.isOpen(target)) {
            window.location.hash = target.dataset.hash
          } else {
            window.location.hash = ""
          }
        }
      }
    }
  }

  toggleElement(target) {
    const el = target.closest("[data-toggle-element]")
    el.classList.toggle(target.getAttribute("data-toggle-class") || "toggled")

    if (
      target.hasAttribute("data-toggle-callback") &&
      target.hasAttribute("data-toggle-callback-scope")
    ) {
      const callbackScope =
        target.closest(target.getAttribute("data-toggle-callback-scope")) ||
        document.querySelector(
          target.getAttribute("data-toggle-callback-scope"),
        )
      this[target.getAttribute("data-toggle-callback")].call(
        callbackScope,
        target.getAttribute("data-toggle-class") || "toggled",
      )
    }
  }

  isOpen(target) {
    const el = target.closest("[data-toggle-element]")
    return el.classList.contains(
      target.getAttribute("data-toggle-class") || "toggled",
    )
  }

  foldoutSetSize(toggleClass) {
    if (!this) return

    const wrapper = this.querySelector(".js-foldout-wrapper")
    const body = this.querySelector(".js-foldout-body")

    if (!wrapper || !body) return

    if (this.classList.contains(toggleClass)) {
      wrapper.style.height = `${body.offsetHeight}px`
    } else {
      wrapper.style.height = "0px"
    }
  }
}

export const initToggleElements = () => {
  new ToggleElements()
}
