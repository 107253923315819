const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const setHeights = (details) => {
  const initiallyOpen = details.open
  details.open = true
  details.style.setProperty("--expanded", "auto")
  details.style.setProperty("--expanded", `${details.offsetHeight}px`)
  details.open = false
  details.style.setProperty("--collapsed", `${details.offsetHeight}px`)
  details.open = initiallyOpen
}

export const initDetails = () => {
  const setDetailsHeights = () => {
    const details = document.querySelectorAll("[data-details]")
    details.forEach((detail) => setHeights(detail))
  }

  window.addEventListener("load", debounce(setDetailsHeights, 100))
  window.addEventListener("resize", debounce(setDetailsHeights, 100))
  window.addEventListener("cmp-embed-loaded", debounce(setDetailsHeights, 100))
}
