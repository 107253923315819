function showByMarks(marks, resetValues) {
  for (const el of document.querySelectorAll(
    "[data-show-for-marks],[data-hide-for-marks]",
  )) {
    const requiredMarks = (el.dataset.showForMarks || "")
      .split(",")
      .filter((m) => m !== "")
    const unwantedMarks = (el.dataset.hideForMarks || "")
      .split(",")
      .filter((m) => m !== "")

    if (
      !requiredMarks.every((m) => marks.includes(m)) ||
      unwantedMarks.some((m) => marks.includes(m))
    ) {
      el.style.display = "none"
      for (const inputEl of el.querySelectorAll(
        "input:enabled,select:enabled",
      )) {
        // Reset field value whenever it's hidden. Not the most
        // helpful behavior for when the field reappears, but it's
        // much easier to keep the form values in a consistent state
        // for submitting (the main problem being fields that are
        // not visible anymore but still submitted).
        const resetValue = resetValues[inputEl.name]
        if (resetValue === undefined) {
          continue
        }
        if (inputEl.type === "checkbox") {
          inputEl.checked = resetValue
        } else if (inputEl.type === "radio") {
          inputEl.checked = inputEl.value === resetValue
        } else {
          inputEl.value = resetValue
        }
      }
    } else {
      el.style.display = null
    }
  }
}

async function loadZipMap(form) {
  const uri = form.dataset.zipEndpoint
  const data = await fetch(uri)
  return await data.json()
}

export class Signup {
  constructor(form) {
    this.form = form
    this.regionLegalformMapping = JSON.parse(
      document.querySelector("#region-legalform-mapping").textContent,
    )
    this.resetValues = JSON.parse(
      document.querySelector("#reset-values").textContent,
    )
    this.zipMap = undefined
    this.init()
  }

  async init() {
    const markNames = ["srg_region", "srg_section", "zip"]

    // set the zip value after a form validation error
    this.form.querySelector("[name=zip]").value =
      this.form.querySelector("[name=zip_code]").value || ""

    this.form.addEventListener("change", (e) => {
      if (markNames.includes(e.target.name)) {
        showByMarks(this.collectMarks(), this.resetValues)
      }
      // if (e.target.name === "zip") {
      //   this.setRegionByZip(e.target.value)
      // }
    })

    this.form.addEventListener("keypress", (e) => {
      if (
        (e.target.name === "zip_button" || e.target.name === "zip") &&
        e.keyCode === 13
      ) {
        e.preventDefault()
        this.setRegionByZip(e.target.value)
      }
    })

    this.form.addEventListener("click", (e) => {
      if (e.target.name === "zip_button") {
        e.preventDefault()
        const zip = document.querySelector("#zip").value
        this.setRegionByZip(zip)
      }
    })

    // init default state
    showByMarks(this.collectMarks(), this.resetValues)

    this.zipMap = await loadZipMap(this.form)
  }

  showZipError(show) {
    this.form.querySelector(".zip-error").classList.toggle("hide", !show)
  }

  setRegionByZip(zip) {
    this.showZipError(false)

    for (const i of this.form.querySelectorAll("[name=srg_region]")) {
      i.checked = false
    }

    if (this.zipMap) {
      const zipMatch = this.zipMap?.[zip]
      if (zipMatch) {
        const region = this.form.querySelector(
          `[name=srg_region][value="${zipMatch[0]}"]`,
        )
        const section = this.form.querySelector(
          `[name=srg_section][value="${zipMatch[1]}"]`,
        )

        if (region) {
          this.form.querySelector("[name=zip_code]").value = zip
          region.checked = true
          // showByMarks(this.collectMarks(), this.resetValues)
          showByMarks(
            [
              "zipSelected",
              zip,
              "regionSelected",
              zipMatch[0],
              this.regionLegalformMapping[zipMatch[0]],
            ],
            this.resetValues,
          )
        }

        if (zipMatch[1] && section) {
          section.checked = true
        }
        const anker = document.querySelector("[data-anker]")
        if (anker) {
          anker.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          })
        }
      } else {
        this.showZipError(true)
        showByMarks(["zipSelected", ""], this.resetValues)
        this.form
          .querySelector(".foldout-regions:not(.toggled) .foldout-trigger")
          ?.dispatchEvent(new Event("click", { bubbles: true }))
      }
    }
  }

  collectMarks() {
    const data = new FormData(this.form)
    const marks = []

    const zip = data.get("zip")
    if (zip) {
      marks.push("zipSelected", zip)
    }

    const srgRegion = data.get("srg_region")
    if (srgRegion) {
      marks.push(
        "regionSelected",
        srgRegion,
        this.regionLegalformMapping[srgRegion],
      )
    }

    const srgSection = data.get("srg_section")
    if (srgSection) {
      marks.push("sectionSelected", srgSection)
    }

    return marks
  }
}

export class PaymentForm {
  constructor(form) {
    this.form = form
    this.resetValues = JSON.parse(
      document.querySelector("#reset-values").textContent,
    )
    this.init()
  }

  init() {
    this.form.addEventListener("change", (e) => {
      if (e.target.name === "payment_method") {
        showByMarks(this.collectMarks(), this.resetValues)
      }
    })

    // init default state
    showByMarks(this.collectMarks(), this.resetValues)
  }

  collectMarks() {
    const data = new FormData(this.form)
    const marks = []

    const paymentMethod = data.get("payment_method")
    if (paymentMethod) {
      marks.push(paymentMethod)
    }

    return marks
  }
}

export class ReferrerForm {
  constructor(form) {
    this.form = form
    this.resetValues = {}
    this.init()
  }

  init() {
    this.form.addEventListener("change", (e) => {
      if (e.target.name === "referrer") {
        showByMarks(this.collectMarks(), this.resetValues)
      }
    })

    // init default state
    showByMarks(this.collectMarks(), this.resetValues)
  }

  collectMarks() {
    const data = new FormData(this.form)
    const marks = []

    const referrer = data.get("referrer")
    if (referrer) {
      marks.push(referrer)
    }

    return marks
  }
}
