import Splide from "@splidejs/splide"
import { qs, qsa } from "./utils"

export const initGalleries = () => {
  const galleries = qsa("[data-gallery]")

  galleries.forEach((slider) => {
    const gallery = qs("[data-gallery-slider]", slider)
    const bar = qs("[data-gallery-progress]", slider)
    const thumbnails = qs("[data-gallery-thumbnails]", slider)

    const splideMain = new Splide(gallery, {
      type: "fade",
      pagination: false,
      width: "100%",
      cover: true,
      heightRatio: 0.5625,
      lazyLoad: "nearby",
    })

    const splideThumbnails = new Splide(thumbnails, {
      fixedWidth: 100,
      fixedHeight: 100,
      arrows: false,
      gap: "0.5rem",
      pagination: false,
      isNavigation: true,
      focus: "center",
      trimSpace: false,
      lazyLoad: "sequential",
      updateOnMove: true,
      //   breakpoints: {
      //     600: {
      //       fixedWidth: 60,
      //       fixedHeight: 44,
      //     },
      //   },
    })

    splideMain.on("mounted move", () => {
      const end = splideMain.Components.Controller.getEnd() + 1
      const rate = Math.min((splideMain.index + 1) / end, 1)
      bar.style.width = `${String(100 * rate)}%`
    })

    splideMain.sync(splideThumbnails)
    splideMain.mount()
    splideThumbnails.mount()
  })
}
