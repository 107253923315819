export function initRegionMap() {
  const regionMaps = document.querySelectorAll(".region-map")

  for (const regionMap of regionMaps) {
    const regionItems = regionMap.querySelectorAll("[data-region]")

    function toggleRegion(target, toggle) {
      const targetRegion = target?.closest("[data-region]")?.dataset?.region
      if (!targetRegion) return
      for (const regionItem of regionItems) {
        if (regionItem.dataset?.region === targetRegion) {
          regionItem.classList.toggle("active", toggle)
        }
      }
    }

    regionMap.addEventListener("mouseover", (e) => {
      const target = e.target
      toggleRegion(target, true)
    })

    regionMap.addEventListener("mouseout", (e) => {
      const target = e.target
      toggleRegion(target, false)
    })

    regionMap.querySelector(".map").addEventListener("click", (e) => {
      const targetRegion = e.target?.dataset?.region
      if (!targetRegion) return
      regionMap.querySelector(`li[data-region="${targetRegion}"] a`)?.click()
    })
  }
}
