class VideoPlayer {
  constructor(container, wrapper, play, pause, video) {
    this.container = container
    this.wrapper = wrapper
    this.play = play
    this.pause = pause
    this.video = video

    this.triggers = [this.play, this.pause, this.video]

    this.videoWidth = 1280
    this.videoHeight = 720
    this.isPlaying = false

    this.initPlayer()
    this.addEventListeners()
    this.isFullScreen = () =>
      !!(
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement ||
        document.fullscreenElement
      )
    this.container.dataset.initialized = true
  }

  clickHandler = () => {
    if (this.video.paused || this.video.ended) {
      this.video.play()
      this.switchInterface(true)
    } else {
      this.video.pause()
      this.switchInterface(false)
    }
  }

  videoExitedFullscreen = () => {
    if (
      document.fullScreenElement ||
      document.webkitIsFullScreen === true ||
      document.mozFullScreen ||
      document.msFullscreenElement
    ) {
      this.switchInterface(true)
    } else {
      this.switchInterface(false)
      this.video.controls = false
    }
  }

  switchInterface = (props) => {
    if (props) {
      this.video.setAttribute("style", "width: 100%; height: 100%;")
      this.isPlaying = props
      this.wrapper.style.opacity = 0
      this.wrapper.querySelector(".member__content-box").style.pointerEvents =
        "none"
      this.pause.style.opacity = 1
      document.querySelector(".section-scroll").style.opacity = 0
      this.play.style.opacity = 0
    } else {
      this.video.removeAttribute("style")
      this.wrapper.style.opacity = 1
      this.wrapper.querySelector(".member__content-box").style.pointerEvents =
        "auto"
      document.querySelector(".section-scroll").style.opacity = 1
      this.play.style.opacity = 1
      this.pause.style.opacity = 0
    }
  }

  initPlayer = () => {
    if (document.createElement("video").canPlayTyp) {
      return
    }

    if ("initialized" in this.video.dataset) {
      return
    }

    this.video.controls = false
    for (const el of this.triggers) {
      el.addEventListener("click", this.clickHandler)
    }
    this.video.onended = () => {
      this.switchInterface(false)
      this.video.load()
    }

    // this.video.addEventListeners('finish', this.switchInterface(false))
    // this.fs.addEventListener('click', this.handleFullscreen)
  }

  resizePlayer = () => {
    // get the parent element size
    const containerWidth = this.video.parentNode.clientWidth
    const containerHeight = this.video.parentNode.clientHeight

    // use largest scale factor of horizontal/vertical
    const scaleWidth = containerWidth / this.videoWidth
    const scaleHeight = this.videoHeight / containerHeight
    const scale = scaleWidth > scaleHeight ? scaleWidth : scaleHeight

    // scale the video
    this.video.style.width = `${scale * this.videoWidth}px`
    this.video.style.height = `${scale * this.videoHeight}px`
  }

  addEventListeners = () => {
    this.video.addEventListener(
      "webkitendfullscreen",
      this.videoExitedFullscreen,
    )
  }
}

export const initVideoPlayers = () => {
  // Video Player
  const videoSection = document.querySelectorAll(
    "[data-video-wrapper]:not([data-initialized])",
  )

  for (const el of videoSection) {
    const hasSource = el.querySelector("[data-video] source")
    if (hasSource) {
      new VideoPlayer(
        el,
        el.querySelector("[data-video-inner-wrapper]"),
        el.querySelector("[data-video-play]"),
        el.querySelector("[data-video-pause]"),
        el.querySelector("[data-video]"),
      )
    }
  }
}
