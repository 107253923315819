import Splide from "@splidejs/splide"
import { qsa } from "./utils"

export const initSomeSlider = () => {
  const sliders = qsa("[data-some-slider]")

  sliders.forEach((slider) => {
    const splide = new Splide(slider, {
      type: "loop",
      pagination: false,
      padding: "calc((100% - min(var(--content-width), 100%)) / 2)",
      heightRatio: 1,
      lazyLoad: "nearby",
      gap: "1.5rem",
      snap: true,
      autoplay: true,
      preloadPages: 2,
      mediaQuery: "min",
      breakpoints: {
        640: {
          padding: "calc((100% - min(var(--content-width), 31.8%)) / 2)",
        },
      },
    })

    splide.mount()
  })
}
